import React, { useState, useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import Navbar from "../../components/navbar"
import Footer from "../../components/footer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa"

import { MdOutlineWatchLater } from "react-icons/md"
import Seo from "../../components/seo"
import BlogMostRecent from "../../components/BlogMostRecent"

const Blog = (blogdata, backlink) => {
  const blog = blogdata.data.blog.edges[0].node
  const NavbarData = blogdata?.data?.navbar?.edges?.[1]?.node
  const footerData = blogdata?.data?.footerColumn?.edges?.[1]?.node
  const relatedPosts = blogdata.data.blog.edges[0].node.relatedPosts
  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="heading1 font-workSans font-medium leading-tight text-5xl mt-0 mb-2 text-blue-600">
          {children}
        </h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="heading2 font-workSans font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="heading3 font-workSans font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="heading4 font-workSans font-medium leading-tight text-2xl mt-0 mb-2 text-blue-600">
          {children}
        </h4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h4 className="heading5 font-workSans font-medium leading-tight text-xl mt-0 mb-2 text-blue-600">
          {children}
        </h4>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h4 className="heading6 font-workSans font-medium leading-tight text-base mt-0 mb-2 text-blue-600">
          {children}
        </h4>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (node.content[0].value === "") {
          return <br />
        } else {
          return (
            <p className="text-[19px] font-normal font-workSans leading-6">
              {children}
            </p>
          )
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img
          src={`https:${node.data.target.fields.file["en-US"].url}`}
          alt=""
        />
      ),
      [BLOCKS.UL_LIST]: (node, children) => {
        return (
          <ul className="list-disc ml-7">
            <li className="text-black font-workSans font-normal leading-6 mt-4 text-left text-[19px]">
              {children}
            </li>
          </ul>
        )
      },
    },
    renderMark: {
      [MARKS.BOLD]: text => <b className="font-bold  leading-6">{text}</b>,
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  }
  const featuredPosts = blogdata.data.blog.edges[0].node

  const title = featuredPosts?.articleTitle
  const metaDescription = featuredPosts?.synopsis
  const imageUrl = featuredPosts?.articleBannerImage?.file?.url
  const url = typeof window !== "undefined" ? window.location.href : ""

  const wordCount = useMemo(() => {
    var rawContent = JSON.parse(blog.articleBody.raw)
    let totalStr = 0
    rawContent.content.forEach(content => {
      content.content.forEach(ct => {
        if (ct.value !== undefined && ct.value !== null && ct.value !== "")
          totalStr += ct.value.split(" ").length
      })
    })
    return totalStr
  }, [blog.articleBody])

  const [scrollSection, setScrollSection] = useState(false)

  const changeSectionScroll = () => {
    if (window.scrollY > 500 && window.innerWidth > 450) {
      setScrollSection(true)
    } else {
      setScrollSection(false)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", changeSectionScroll)
  }, [])
  return (
    <>
      <Seo
        title={title}
        metaDescription={metaDescription}
        imageUrl={imageUrl}
        url={url}
      />
      {NavbarData && <Navbar NavbarData={NavbarData} />}
      {/* <BlogFeatured featuredPosts={featuredPosts} backlink={true} /> */}
      <div className="w-screen relative blog-gradient">
        <div className="absolute top-0 h-full -z-20">
          <img
            src={blog.articleBannerImage.file.url}
            alt=""
            className="object-cover w-screen h-full"
          />
        </div>
        <div className="px-5 md:px-10 xl1:px-40  2xl:px-[70px] md:-mt-[118px]">
          <div className="flex items-center justify-start max-w-screen-xl mx-auto min-h-[500px] md:min-h-[630px]">
            <div
              className={`w-full items-center md:w-3/5 p-0 ${
                backlink === true ? " md:mt-[189px]" : "md:mt-[209px]"
              }`}
            >
              <p
                className={`text-base Figtree-SemiBold text-blue uppercase font-semibold leading-4 tracking-widest`}
              >
                {blog?.tags}
              </p>
              <h1 className="pt-[10px] text-[28px] md:text-[44px] font-normal text-ProjectBlack Saira-Bold leading-lineheight120">
                {blog?.articleTitle}
              </h1>
              <p className="pt-[10px] font-workSans font-normal md:text-[19px] text-ProjectBlack leading-6">
                {blog?.synopsis}
              </p>
              {backlink && (
                <div className="flex items-center rounded mt-[40px] md:mb-[71px]">
                  <img
                    srcset="//images.ctfassets.net/tafzvdx4ctpz/2JqK5JiGZescLVHlH5fq6R/da074fcea2bb448f33deb44c9de9eae9/iTunesArtwork.png?w=200 1x"
                    className="w-[64px] h-[64px] rounded-full"
                    alt=""
                  />
                  <div className="ml-[21px]">
                    <p className="text-TextPrimaryWhite font-workSans font-bold text-[19px] leading-6">
                      Atlas One
                    </p>
                    <p className="text-TextPrimaryWhite font-workSans font-normal text-base leading-6">
                      Customer Success Team
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="w-screen bg-white mx-auto py-[42px] px-5 md:px-10 xl1:px-40">
        <div className="relative max-w-screen-xl mx-auto w-full flex flex-col lg:flex-row items-stretch mb-6 lg1:flex-row gap-x-4 md:gap-x-0 lg1:gap-y-0 gap-y-4 md:mb-9">
          <div
            className={`hidden lg:block md:w-full lg:w-1/3 max-w-[370px] pt-[12px] pr-[24px] ${
              scrollSection === true ? "fixed top-[88px]" : ""
            }`}
          >
            <p className="Saira-Bold capitalize font-normal md:text-[36px] text-black leading-lineheight120 tracking-atswide">
              Ready to get started?
            </p>
            <p className="pt-[14px] pb-[27px] font-workSans  font-normal md:text-[17px] text-black leading-6">
              Connect with an Atlas One product expert today to learn how to
              modernize your citizen engagement strategy
            </p>
            <div className="flex flex-row">
              <a
                class="flex items-center justify-center h-[3.125rem] font-normal text-center uppercase border-2 rounded-sm font-industryBold min-w-[140px] md:min-w-[150px] text-xs md:text-sm tracking-widest 
                      bg-PrimaryBlue border-ProjectBlue text-TextPrimaryWhite hover:bg-PrimaryHoverBlue hover:border-PrimaryHoverBlue duration-300 ease-in-out"
                href="/talk-to-sales/"
              >
                Get Started
              </a>
            </div>
          </div>
          <div
            className={`md:w-full lg:w-2/3 md:pl-[52px] ${
              scrollSection === true ? "ml-[370px]" : ""
            }`}
          >
            <div className="flex flex-row justify-between items-center flex-wrap">
              <div className="flex lg:w-4/5 items-center pt-[1.5px]">
                <MdOutlineWatchLater size={30} />
                <p className="ml-[20px] leading-6 font-workSans font-medium">
                  {Math.ceil(wordCount / 200)} minute read
                </p>
              </div>
              <div className="flex justify-end items-center lg:w-1/5">
                <FacebookShareButton url={url}>
                  <div className="flex items-center justify-center mr-2 md:mr-8  ">
                    <FaFacebookF
                      className="text-black hover:text-ProjectBlue"
                      size={24}
                    />
                  </div>
                </FacebookShareButton>
                <TwitterShareButton url={url}>
                  <div className="flex items-center justify-center mr-2 md:mr-8 ">
                    <FaTwitter
                      className="text-black hover:text-ProjectBlue"
                      size={24}
                    />
                  </div>
                </TwitterShareButton>
                <LinkedinShareButton url={url}>
                  <div className=" flex items-center justify-center">
                    <FaLinkedinIn
                      className="text-black hover:text-ProjectBlue"
                      size={24}
                    />
                  </div>
                </LinkedinShareButton>
              </div>
            </div>
            <hr className="border-b-[1px] mt-[25px] mb-[30px]" />

            <div className="block articlebody">
              {renderRichText(blog.articleBody, options)}
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        {relatedPosts && (
          <BlogMostRecent
            data={relatedPosts}
            renderPagination={false}
            limit={2}
          />
        )}
        <Footer
          variant="dark"
          footerData={footerData?.footerColumns}
          atlasOneLogo={footerData?.atlasOneLogo}
        />
      </div>
    </>
  )
}
export default Blog

export const query = graphql`
  query BlogDetailQuery($slug: String!) {
    blog: allContentfulArticle(filter: { articleTitle: { eq: $slug } }) {
      edges {
        node {
          articleBannerImage {
            file {
              url
            }
          }
          articleTitle
          articleBody {
            raw
          }
          tags
          featured
          popular
          synopsis
          updatedAt
          relatedPosts {
            title
            articlesresources {
              ... on ContentfulArticle {
                id
                articleTitle
                tags
                articleBannerImage {
                  file {
                    url
                  }
                }
                synopsis
              }
              ... on ContentfulResource {
                id
                title
                actionText
                resourceTag
                resourceGraphic {
                  file {
                    url
                  }
                }
                synopsis
              }
            }
            layout
          }
        }
      }
    }
    navbar: allContentfulHeader {
      edges {
        node {
          atlasOneLogo {
            image {
              file {
                url
              }
              title
            }
          }
          headerButtons {
            ... on ContentfulPrimaryButton {
              elementType
              id
              theme
              title
              url
            }
            ... on ContentfulSecondaryButton {
              elementType
              id
              theme
              title
              url
            }
          }
          headerMenuItems {
            id
            title
            headerMenuDropdown {
              items {
                description
                icon {
                  file {
                    url
                  }
                  title
                }
                title
                url
                page {
                  slug
                  title
                }
              }
            }
          }
        }
      }
    }
    footerColumn: allContentfulFooter {
      edges {
        node {
          elementType
          footerColumns {
            columnTitle
            links {
              title
              description
              icon {
                file {
                  url
                }
              }
              url
              page {
                slug
                title
              }
            }
          }
          atlasOneLogo {
            image {
              file {
                url
              }
              title
            }
          }
        }
      }
    }
    featured: allContentfulArticle(filter: { featured: { eq: true } }) {
      edges {
        node {
          articleBannerImage {
            file {
              url
            }
          }
          articleTitle
          tags
          featured
          popular
          synopsis
          updatedAt
        }
      }
    }
    allContentfulPage {
      edges {
        node {
          slug
          title
          metaTitle
          metaDescription
          ogImage {
            file {
              url
            }
          }
          sections {
            ... on ContentfulHeader {
              headerMenuItems {
                title
                headerMenuDropdown {
                  items {
                    description
                    icon {
                      file {
                        url
                      }
                      title
                    }
                    title
                    url
                    page {
                      slug
                      title
                    }
                  }
                }
              }
              headerButtons {
                ... on ContentfulPrimaryButton {
                  elementType
                  id
                  theme
                  title
                  url
                }
                ... on ContentfulSecondaryButton {
                  elementType
                  id
                  theme
                  title
                  url
                }
              }
              atlasOneLogo {
                image {
                  file {
                    url
                  }
                  title
                }
              }
            }
            ... on ContentfulNotice {
              theme
              noticeTitle {
                raw
              }
              tagline
              actionUrl
              noticeActionText
            }
            ... on ContentfulHero {
              heroBackgroundGradient
              heroBackgroundImage {
                file {
                  url
                }
                title
              }
              content {
                actionText
                actionUrl
                contentBlockDescription {
                  raw
                }
                contentBlockTagline
                contentBlockTitle
                buttons {
                  ... on ContentfulPrimaryButton {
                    id
                    theme
                    title
                  }
                  ... on ContentfulSecondaryButton {
                    id
                    theme
                    title
                  }
                }
              }
              heroType
            }
            ... on ContentfulFooter {
              elementType
              ... on Node {
                ... on ContentfulFooterColumn {
                  columnTitle
                  links {
                    title
                    description
                    icon {
                      file {
                        url
                      }
                    }
                    url
                    page {
                      slug
                      title
                    }
                  }
                }
              }
              atlasOneLogo {
                image {
                  file {
                    url
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`
